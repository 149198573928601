import React from 'react'

function WorkSection() {
  return (
    <div>
      <h2>work</h2>
    </div>
  )
}

export default WorkSection
