import React from 'react';

function About() {
    return (
        <div>
            <h2 className='about'>
                I am a proud alumnus of the Flatiron School, having completed my studies in 2023. My journey in web development is showcased through my personal website, sammypgaddis.com, which I crafted using React.js.
                <br/>The website not only serves as a portfolio of my projects but also as a demonstration of my technical proficiency. It is hosted on AWS, leveraging S3 for static hosting. My experience encompasses not just the deployment of this site using Terraform, but also a comprehensive understanding of AWS services, including the management of S3 buckets and the configuration of Route 53 for domain management.
                <br/>
                <br/>Beyond my professional pursuits, I am an avid enthusiast of outdoor adventures, fitness, and socializing with friends. My passion for exploring new hobbies and cultures is a testament to my commitment to lifelong learning and personal growth.
            </h2>
        </div>
    );
}

export default About;
