import React from 'react';


const MyButton = () => {
  const handleButtonClick = () => {
    // Redirect to the external URL when the button is clicked
    window.location.href = 'http://calc.sammypgaddis.com';
  };

  return (
    <button onClick={handleButtonClick}>
      <img className='button'
        src="https://purepng.com/public/uploads/large/purepng.com-calculator-iconsymbolsiconsapple-iosiosios-8-iconsios-8-721522596017qe0p7.png"
        alt="calculator"
        style={{ width: '100px', height: '100px' }}
      />
    </button>
  );
};

export default MyButton;




