import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MyButton from './MyButton';
import About from './About';
import Info from './Info';
import MyComponent from './MyComponent';
import PDFButtonLink from './PDFButtonLink';
import Resume from './Resume';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className='top-page'>
            <h1>I'm Samuel Gaddis</h1>
            <About/>
          </div>
          <>
            <MyComponent/>
          </>

          <>
          <Routes>
            <Route path="/" element={<PDFButtonLink/>} />
          </Routes>
          </>

          <>
          <Resume/>
          </>
          <br/>
          
          <>
            <Info/>
          </>
          <br/>

          <Routes>
            <Route path='/' element={<MyButton/>} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
