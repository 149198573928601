import React from 'react'

function EducationSection() {
  return (
<div className="education-section">
      <div className="section-title">
        <h2>Education</h2>
      </div>
      <div className="education-details">
        <div className="what-i-learned">
          <h3>What I Learned:</h3>
          <p>Your learned skills or major</p>
        </div>
        <div className="school-name">
          <h3>School Name:</h3>
          <p>Your school's name</p>
        </div>
      </div>
    </div>
  )
}

export default EducationSection
