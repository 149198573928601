import React from 'react';

function Info() {
    return(
        <div className='info-page'>
            <h5>More about me</h5>
            <p><strong>Location:</strong> Canton, OH</p>
            <p><strong>Nationality:</strong> Hawaiian</p>
            <p><strong>Study:</strong> Flatiron School</p>
            <p><strong>Age:</strong> 28</p>
            <p><strong>Interests:</strong> Gym, travels, and motorcycles</p>
            <p><strong>Employment:</strong> Still on the search</p>
        </div>
    )
}

export default Info;