import React from 'react'
import EducationSection from './EducationSection'
import WorkSection from './WorkSection'

function Resume() {
  return (
    <div>
      <EducationSection/>
      <WorkSection/>
    </div>
  )
}

export default Resume
