import React from 'react'

function PDFButtonLink() {
  const pdfButton = () => {
    window.location.href = 'https://docs.google.com/document/d/17K00l7kriLSK23NaNP1v5XONjYUVhqz6XbmnQgTmPqM/edit?usp=sharing';
  };

  return (
    <div>
            <button onClick={pdfButton} className='pdf-button'>Sams PDF Resume</button>
    </div>
  )
}

export default PDFButtonLink
