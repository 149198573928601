import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaMedium } from "react-icons/fa6";

function MyComponent() {
  return (
    <div>
      <a className='github' href="https://github.com/sammypgaddis" target="_blank" rel="noopener noreferrer">
        <FaGithub size={30} />
      </a>
      <a className='linkedin' href="https://www.linkedin.com/in/sammypgaddis/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={30} />
      </a>
      <a className='medium' href="https://medium.com/@s.gad5239" target="_blank" rel="noopener noreferrer">
        <FaMedium size={30} />
      </a>
    </div>
  )
}

export default MyComponent
